.register-nav-w-animation-enter {
    -webkit-transform: translate3d(0, -101%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, -101%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, -101%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, -101%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
}

.register-nav-w-animation-enter-active {
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: transform 300ms;
}

.register-nav-w-animation-enter-done {
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}


.register-wrapper-animation-enter {
    -webkit-transform: translate3d(0, 50px, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 50px, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 50px, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 50px, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    opacity: 0
}

.register-wrapper-animation-enter-active {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
    transition: all 300ms;
}
.register-wrapper-animation-enter-done {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
}



.shape-1-animation-enter {
    opacity: 0;
    -webkit-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
}
.shape-1-animation-enter-active {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 300ms;
}
.shape-1-animation-enter-done {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}


.shape-2-animation-enter {
    opacity: 0;
    -webkit-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(-60DEG) skew(0, 0);
    -moz-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(-60DEG) skew(0, 0);
    -ms-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(-60DEG) skew(0, 0);
    transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(-60DEG) skew(0, 0)
}
.shape-2-animation-enter-active {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-60deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 300ms;
}
.shape-2-animation-enter-done {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-60deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.shape-3-animation-enter {
    opacity: 0;
    -webkit-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(40DEG) skew(0, 0);
    -moz-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(40DEG) skew(0, 0);
    -ms-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(40DEG) skew(0, 0);
    transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(40DEG) skew(0, 0)
}
.shape-3-animation-enter-active {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 300ms;
}
.shape-3-animation-enter-done {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}


.shape-4-animation-enter {
    opacity: 0;
    -webkit-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
    transform:translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)
}
.shape-4-animation-enter-active {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    will-change: transform;
    transition: all 300ms;
}
.shape-4-animation-enter-done {
    opacity: 0.6;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    will-change: transform;
}


.remember-checkbox-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}


.main-sign-wrapper {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    color: #161616;

    font-weight: 400;
}

.main-sign-wrapper.no-overflow {
    overflow: visible;
}

.main-sign-wrapper.register-page {
    font-family: Neuehaasdisplay, sans-serif;
    color: #161616;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    min-height: 100vh;
    padding-top: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    flex-wrap: nowrap;
}

.sign-nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
    padding: 12px 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    font-family: Neuehaasdisplay, sans-serif;
    color: #161616;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.sign-nav-link:hover {
    color: #ee1670;
    text-decoration: underline;
}

.sign-nav-link.w--current {
    color: #ee1670;
    text-decoration: underline;
}

.nav-logo {
    cursor: pointer;
}

.main-sign-wrapper .emoji-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    background-image: url('./images/070ccbbb696c2975bb0da521c2ffc5.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: Darkergrotesque, sans-serif;
    font-size: 28px;
    line-height: 16px;
}

.main-sign-wrapper .emoji-icon.min-emoji {
    position: relative;
    left: 3px;
    top: 0px;
    width: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.shapes-w {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    background-image: none;
    -webkit-filter: blur(0px);
    filter: blur(0px);
}

.shape-1 {
    position: absolute;
    left: -10vw;
    top: -10%;
    display: block;
    width: 28vw;
    max-width: 500px;
    /*opacity: 0.6;*/
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.shape-2 {
    position: absolute;
    top: -23%;
    right: 6.5vw;
    display: block;
    width: 20vw;
    max-width: 500px;
    /*opacity: 0.6;*/
    /*-webkit-transform: rotate(-60deg);*/
    /*-ms-transform: rotate(-60deg);*/
    /*transform: rotate(-60deg);*/
    -webkit-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
}

.shape-3 {
    position: absolute;
    left: -7.5vw;
    bottom: 11%;
    display: block;
    width: 26vw;
    max-width: 500px;
    /*opacity: 0.6;*/
    /*-webkit-transform: rotate(40deg);*/
    /*-ms-transform: rotate(40deg);*/
    /*transform: rotate(40deg);*/
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
}

.shape-4 {
    position: absolute;
    right: -6.4vw;
    bottom: -15%;
    display: block;
    width: 30vw;
    max-width: 500px;
    /*opacity: 0.6;*/
    -webkit-filter: hue-rotate(290deg);
    filter: hue-rotate(290deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.text-field {
    height: 44px;
    margin-bottom: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(22, 22, 22, 0.5);
    border-radius: 3px;
    color: #161616;
    letter-spacing: 0.5px;
}

.text-field:active {
    border-color: #161616;
    color: #161616;
}

.text-field:focus {
    border-color: #161616;
}

.text-field::-webkit-input-placeholder {
    color: rgba(22, 22, 22, 0.6);
    letter-spacing: 0.5px;
}

.text-field:-ms-input-placeholder {
    color: rgba(22, 22, 22, 0.6);
    letter-spacing: 0.5px;
}

.text-field::-ms-input-placeholder {
    color: rgba(22, 22, 22, 0.6);
    letter-spacing: 0.5px;
}

.text-field::placeholder {
    color: rgba(22, 22, 22, 0.6);
    letter-spacing: 0.5px;
}

.text-field.add-indent {
    margin-bottom: 15px;
}

.main-sign-wrapper .button {
    margin-left: 15px;
    padding: 10px 23px;
    border: 2px solid #000;
    border-radius: 3px;
    background-color: #161616;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: Neuehaasdisplay, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.main-sign-wrapper .button:hover {
    background-color: transparent;
    color: #161616;
}

.main-sign-wrapper .button:hover .button-loader-wrap .default-spinner-color {
    color: #161616;
}


.main-sign-wrapper .button:active {
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
}

.main-sign-wrapper .button.full-w {
    height: 48px;
    margin-top: 15px;
    margin-left: 0px;
}

.form-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 600px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.sign-logo-img {
    width: 160px;
}

.register-nav-w {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 4%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.register-wrapper {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    max-width: 1200px;
    margin: 100px auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.register-col-1 {
    width: 50%;
    padding: 60px 60px 60px 80px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #eef7fd;
}

.register-col-1.center-align {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.register-col-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 48%;
    min-height: 600px;
    padding: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 25px 0 rgba(60, 60, 60, 0.15);
    text-align: center;
}

.register-col-2.no-min-h {
    min-height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
}

.google-icon {
    width: 25px;
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.form-block-4 {
    width: 100%;
    margin-bottom: 0px;
}

.google-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 11px 40px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #000;
    border-radius: 3px;
    font-family: Neuehaasdisplay, sans-serif;
    color: #1b1b1b;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.google-btn:hover {
    background-color: #1b1b1b;
    color: #fff;
}

.sign-link-3 {
    color: #1b1b1b;
    text-decoration: underline;
    cursor: pointer;
}

.sign-link-3:hover {
    text-decoration: none;
}

.sign-heading {
    font-family: Darkergrotesque, sans-serif;
    font-size: 52px;
    line-height: 54px;
    font-weight: 900;
}

.log-in-text-3 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0.5px;
}

.google-signup-w {
    display: none;
    margin-bottom: 20px;
}

.or-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #1b1b1b;
    line-height: 15px;
    font-weight: 400;
}

.grey-line {
    width: 45%;
    height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #ccc;
}

.register-nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.sign-info-header {
    margin-bottom: 40px;
    font-family: Darkergrotesque, sans-serif;
    font-size: 40px;
    line-height: 38px;
    font-weight: 700;
}

.sign-info-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.sign-info-icon {
    width: 30px;
    margin-right: 15px;
}

.register-nav-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.register-nav-text {
    margin-right: 10px;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.register-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.success-message {
    padding: 14px 40px;
    background-color: #eef7fd;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.5px;
}

.error-message-2 {
    display: block;

    background-color: #ffcce1;
    color: #ee1670;
    text-align: center;
    letter-spacing: 0.5px;
}

.main-sign-wrapper .w-form-done {
    padding: 20px;
    text-align: center;
    background-color: #dddddd;
}

.main-sign-wrapper .w-form-fail {
    /* display: none; */
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede;
}

.form-div-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
}

.main-sign-wrapper .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-image: url(./images/remember-checkmark.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.checkbox-label {
    margin-bottom: 0px;
}

.checkbox-field-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkbox-4 {
    width: 17px;
    height: 17px;
    margin-top: 0px;
    margin-right: 8px;
    margin-left: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(22, 22, 22, 0.5);
    border-radius: 3px;
}

.checkbox-4:hover {
    border-color: #161616;
}

.main-sign-wrapper .checkbox-4.w--redirected-checked {
    border-color: #161616;
    background-color: #161616;
    background-size: contain;
}

.sign-info-subheader {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.welcome-text-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.sign-subheader {
    width: 450px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.sign-heading-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.sign-heading-div.min-margin {
    margin-bottom: 20px;
}

.sign-emoji {
    width: 45px;
    height: 45px;
    margin-top: 15px;
    margin-left: 5px;
    background-image: url('./images/d08cf1cdf821eda6128abdf9279caf.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.sign-emoji.welcome-emoji {
    width: 43px;
    height: 43px;
    margin-left: 10px;
    background-image: url('./images/f2180902deba2f6fa719fb382963e0.png');
}


@media screen and (max-width: 991px) {
    .main-sign-wrapper.register-page {
        height: auto;
        padding-top: 50px;
    }

    .main-sign-wrapper .emoji-icon {
        width: 35px;
        height: 35px;
        margin-bottom: 2px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .sign-nav-link {
        display: block;
        margin-right: 0px;
        margin-left: 0px;
        padding: 10px 20px;
        font-size: 14px;
        line-height: 15px;
    }

    .main-sign-wrapper .emoji-icon.min-emoji {
        top: 0px;
        display: inline;
        width: 8px;
        line-height: 16px;
    }

    .shape-1 {
        left: -13vw;
        top: -7%;
        width: 40vw;
    }

    .shape-2 {
        top: -9%;
        right: 10vw;
        width: 35vw;
    }

    .shape-3 {
        left: -18vw;
        bottom: 19%;
        width: 42vw;
    }

    .shape-4 {
        right: -10vw;
        bottom: 0%;
        width: 38vw;
    }

    .text-field.add-indent {
        height: 44px;
        margin-bottom: 13px;
        font-size: 13px;
    }

    .main-sign-wrapper .button {
        font-size: 15px;
    }

    .sign-logo-img {
        width: 130px;
    }

    .register-nav-w {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .register-wrapper {
        width: 96%;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .register-col-1 {
        padding: 50px 30px 50px 40px;
    }

    .register-col-1.center-align {
        min-height: 250px;
    }

    .register-col-2 {
        width: 55%;
        min-height: auto;
        padding: 60px 50px;
    }

    .register-col-2.no-min-h {
        width: auto;
    }

    .form-block-4 {
        width: 100%;
    }

    .google-btn {
        padding-top: 9px;
        padding-bottom: 10px;
        font-size: 15px;
    }

    .shopify-linkblock {
        width: 180px;
    }

    .signup-shopify-block {
        font-size: 1.6em;
        line-height: 1.5em;
    }

    .sign-heading {
        margin-top: 0px;
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 36px;
    }

    .log-in-text-3 {
        font-size: 13px;
        line-height: 18px;
    }

    .google-signup-w {
        margin-bottom: 10px;
    }

    .or-wrap {
        margin-top: 10px;
        font-size: 13px;
    }

    .sign-info-header {
        margin-bottom: 25px;
        font-size: 28px;
        line-height: 28px;
    }

    .sign-info-div {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
    }

    .sign-info-icon {
        width: 25px;
        margin-right: 10px;
    }

    .register-form {
        width: 100%;
    }

    .success-message {
        padding: 15px 30px;
        font-size: 14px;
    }

    .error-message-2 {
        font-size: 13px;
    }

    .sign-info-subheader {
        font-size: 17px;
    }

    .sign-heading-div {
        margin-bottom: 0px;
    }

    .sign-heading-div.min-margin {
        margin-bottom: 10px;
    }

    .sign-emoji {
        width: 40px;
        height: 40px;
        margin-top: -18px;
    }

    .sign-emoji.welcome-emoji {
        width: 40px;
        height: 40px;
        margin-top: -24px;
    }
}

@media screen and (max-width: 767px) {
    .main-sign-wrapper.register-page {
        height: auto;
        padding-top: 0px;
    }

    .sign-nav-link {
        margin-right: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
    }

    .nav-logo {
        top: 1px;
        padding-left: 0px;
    }

    .shape-1 {
        left: -15.2vw;
        width: 40vw;
    }

    .shape-2 {
        right: 9.2vw;
        width: 34vw;
    }

    .shape-3 {
        left: -18.8vw;
        width: 38vw;
    }

    .shape-4 {
        right: -14vw;
        width: 37vw;
    }

    .text-field {
        height: 40px;
        font-size: 13px;
    }

    .main-sign-wrapper .button {
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 14px;
        line-height: 18px;
    }

    .sign-logo-img {
        width: 120px;
    }

    .register-nav-w {
        position: absolute;
        z-index: 20;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .register-wrapper {
        width: 95%;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 100px;
        padding-bottom: 60px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .register-col-1 {
        width: 80%;
        margin-top: 50px;
    }

    .register-col-1.center-align {
        min-height: auto;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-radius: 5px;
    }

    .register-col-2 {
        overflow: visible;
        width: 90%;
        margin-top: 0px;
        padding: 40px;
    }

    .register-col-2.no-min-h {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .google-icon {
        width: 20px;
    }

    .form-block-4 {
        width: 100%;
        max-width: none;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .google-btn {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
    }

    .shopify-linkblock {
        width: 130px;
        margin-top: 5px;
    }

    .signup-shopify-block {
        width: 90%;
        font-size: 1.4em;
    }

    .sign-heading {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 30px;
    }

    .log-in-text-3 {
        font-size: 12px;
        line-height: 16px;
    }

    .google-signup-w {
        margin-bottom: 10px;
    }

    .sign-info-header {
        margin-bottom: 30px;
        font-size: 26px;
    }

    .sign-info-icon {
        margin-right: 12px;
    }

    .register-nav-text {
        margin-right: 0px;
        font-size: 14px;
        line-height: 16px;
    }

    .success-message {
        padding-right: 25px;
        padding-left: 25px;
        font-size: 13px;
    }

    .error-message-2 {
        font-size: 12px;
        line-height: 16px;
    }

    .sign-subheader {
        width: auto;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 22px;
    }

    .sign-emoji {
        width: 35px;
        height: 35px;
        margin-top: -12px;
    }

    .sign-emoji.welcome-emoji {
        width: 35px;
        height: 35px;
        margin-top: -20px;
    }
}

@media screen and (max-width: 479px) {
    .main-sign-wrapper.register-page {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sign-nav-link {
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        padding: 7px 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
    }

    .main-sign-wrapper .emoji-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 2px;
    }

    .main-sign-wrapper .emoji-icon.min-emoji {
        display: inline;
        line-height: 9px;
    }

    .shape-1 {
        left: -19.1vw;
        top: -2.6vh;
        width: 57vw;
    }

    .shape-2 {
        top: -3%;
        right: 10.5vw;
        width: 50vw;
    }

    .shape-3 {
        left: -23vw;
        bottom: 21%;
        width: 60vw;
    }

    .shape-4 {
        right: -21vw;
        bottom: 3%;
        width: 54vw;
    }

    .text-field {
        height: 38px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 12px;
        line-height: 18px;
    }

    .text-field.add-indent {
        height: 40px;
    }

    .main-sign-wrapper .button {
        margin-left: 10px;
        padding: 8px 15px;
        font-size: 13px;
    }

    .main-sign-wrapper .button.full-w {
        margin-top: 10px;
    }

    .form-div {
        width: 100%;
        min-width: auto;
    }

    .sign-logo-img {
        width: 110px;
    }

    .sign-header {
        font-size: 16px;
        line-height: 24px;
    }

    .register-nav-w {
        padding-right: 2%;
        padding-left: 3%;
    }

    .register-wrapper {
        padding-top: 100px;
    }

    .register-col-1 {
        width: 100%;
        margin-top: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 30px;
    }

    .register-col-1.center-align {
        min-height: auto;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .register-col-2 {
        width: 100%;
        margin-top: 0px;
        padding-top: 30px;
        padding-right: 25px;
        padding-left: 25px;
    }

    .google-icon {
        width: 18px;
    }

    .form-block-4 {
        width: 100%;
        max-width: none;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .google-btn {
        padding: 10px 20px;
        font-size: 13px;
        line-height: 14px;
    }

    .sign-link-3 {
        font-size: 11px;
        line-height: 14px;
    }

    .sign-heading {
        margin-top: 0px;
        font-size: 24px;
        line-height: 25px;
        letter-spacing: -0.5px;
    }

    .log-in-text-3 {
        font-size: 11px;
        line-height: 14px;
    }

    .or-wrap {
        margin-top: 10px;
        font-size: 11px;
    }

    .sign-info-header {
        font-size: 23px;
        line-height: 24px;
        text-align: center;
    }

    .sign-info-div {
        font-size: 13px;
    }

    .sign-info-icon {
        margin-right: 12px;
    }

    .register-nav-text {
        font-size: 12px;
        line-height: 14px;
        text-align: right;
    }

    .success-message {
        padding: 15px;
        font-size: 12px;
        line-height: 16px;
    }

    .error-message-2 {
        padding: 15px 20px;
    }

    .checkbox-label {
        font-size: 11px;
        line-height: 14px;
    }

    .sign-info-subheader {
        font-size: 15px;
    }

    .sign-subheader {
        font-size: 13px;
        line-height: 18px;
    }

    .sign-emoji {
        width: 30px;
        height: 30px;
    }

    .sign-emoji.welcome-emoji {
        width: 30px;
        height: 30px;
        margin-top: -18px;
    }
}

@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Mediu.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-RomanItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-Thin.woff') format('woff'), url('./fonts/NeueHaasDisplay-XThin.woff') format('woff'), url('./fonts/NeueHaasDisplay-XXThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Neuehaasdisplay';
    src: url('./fonts/NeueHaasDisplay-ThinItalic.woff') format('woff'), url('./fonts/NeueHaasDisplay-XXThinItalic.woff') format('woff'), url('./fonts/NeueHaasDisplay-XThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: auto;
}

@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}
@font-face {
    font-family: 'Darkergrotesque';
    src: url('./fonts/DarkerGrotesque-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}